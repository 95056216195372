import * as React from 'react';

import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import Image from 'next/image';

import Layout from '../components/common/Layout';
import Strip from '../components/common/Strip';
import { Link } from '../components/elements';
import { withTranslation } from '../translation';

const Custom404 = () => {
  const { t } = useTranslation('404');
  const { SEO, description, button } = t('404', { returnObjects: true }) as any;
  const nfImage = '/images/illustrations/NotFound.png';

  return (
    <Layout SEO={SEO}>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex" />
      </Head>
      <Strip variant="white">
        <div className="not-found-box">
          <Image src={nfImage} className="not-found-box__visual" alt="404" fill={true} />
          <p>{description}</p>
          <Link to={button.path} title={button.title} variant="cta">
            {button.label}
          </Link>
        </div>
      </Strip>
    </Layout>
  );
};

export default Custom404;

export const getStaticProps = withTranslation(async (context) => {
  return {
    props: {},
  };
});
