import * as React from 'react';

import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'react-i18next';

import { SITE_URL } from '../../../constants';
import { ILayout } from './Layout.types';
import {
  generateCurrentPath,
  generateMetaTags,
  generateMetaTitle,
  generateThumbnailImage,
} from './utils';

const Layout: React.FC<ILayout> = ({ SEO, children }) => {
  const { t: layoutT } = useTranslation('layout');

  const { defaultMetaDescription } = layoutT('layout', { returnObjects: true }) as any;
  const { title, meta, image } = SEO || {};
  const metaDescription = meta?.description || defaultMetaDescription;

  const pathname = usePathname();

  // http://localhost:3000/resources/glossary -> /resources/glossary
  const currentPath = generateCurrentPath(pathname);

  const isHomePage = ['/', '/fr-FR/'].includes(currentPath);

  const metaTitle = generateMetaTitle(title || '', isHomePage);
  const thumbnailImage = generateThumbnailImage(image);

  const openGraph = {
    type: 'website',
    title: metaTitle,
    description: metaDescription,
    image: thumbnailImage,
    url: SITE_URL + currentPath,
  };

  return (
    <>
      <Head>
        {/* Meta */}
        <title>{metaTitle}</title>
        {generateMetaTags(openGraph)}
        {generateMetaTags(openGraph, true)}
      </Head>
      <main>{children}</main>
    </>
  );
};

export default Layout;
